import {
  Evidently,
  PutProjectEventsCommandInput,
} from "@aws-sdk/client-evidently";
import { Auth } from "aws-amplify";

import { substringAfter } from "./constants";
import { IEvidentlySetting } from "./definitions";

export class EvidentlyContext {
  static VARIATION: Map<String, any> = new Map();
  static VARIATION_VALUES: Map<String, any> = new Map();
  static isLoading = false;
  static isError = false;

  private static setVariation(name: string, variation: string, value: any) {
    const key = substringAfter(name, "/feature/");
    EvidentlyContext.VARIATION.set(key, variation);
    EvidentlyContext.VARIATION_VALUES.set(key, value);
  }

  static isFeatureEnabled(feature: string, value?: any): boolean {
    return (
      EvidentlyContext.getFeatureVariationValue(feature) === (value ?? true)
    );
  }

  static getFeatureVariation(feature: string) {
    return EvidentlyContext.VARIATION.get(feature);
  }

  static getFeatureVariationValue(feature: string) {
    return EvidentlyContext.VARIATION_VALUES.get(feature);
  }

  static async loadVariations(
    evidentlySettings: IEvidentlySetting,
    loggedInUserAlias: string
  ) {
    let { project, features, region } = evidentlySettings;
    const alias = loggedInUserAlias;
    if (!alias || EvidentlyContext.isLoading) return;
    const start = Date.now();
    const credentials = await Auth.currentCredentials();

    // Initialize the Amazon CloudWatch Evidently client
    const client = new Evidently({
      credentials: credentials,
      region: region,
      retryMode: "standard",
    });
    // API request structure
    const evaluateFeatureRequest = {
      project: project,
      requests: features.map((feature) => ({
        entityId: alias,
        feature: feature,
      })),
    };
    if (!alias || EvidentlyContext.isLoading) return;
    try {
      EvidentlyContext.isLoading = true;
      const evaluation = await client.batchEvaluateFeature(
        evaluateFeatureRequest
      );
      evaluation.results?.forEach((result) => {
        try {
          if (!(result.value && result.feature && result.variation)) {
            throw new Error();
          }
          if (result.value.boolValue != null) {
            EvidentlyContext.setVariation(
              result.feature,
              result.variation,
              result.value.boolValue
            );
          } else if (result.value.doubleValue != null) {
            EvidentlyContext.setVariation(
              result.feature,
              result.variation,
              result.value.doubleValue
            );
          } else if (result.value.longValue != null) {
            EvidentlyContext.setVariation(
              result.feature,
              result.variation,
              result.value.longValue
            );
          } else if (result.value.stringValue != null) {
            EvidentlyContext.setVariation(
              result.feature,
              result.variation,
              result.value.stringValue
            );
          }
        } catch (e) {
          console.error(`Feature ${result.feature} is not exist`);
        }
      });
      EvidentlyContext.isLoading = false;
    } catch (e) {
      EvidentlyContext.isLoading = false;
      EvidentlyContext.isError = true;
      console.error("Feature evaluation failed", e);
    }
    const elapse = Date.now() - start;
    EvidentlyContext.putProjectEvent(client, project, alias, elapse);
  }

  static async putProjectEvent(
    client: Evidently,
    project: string,
    alias: string,
    elapse: any
  ) {
    const _data = `{
        "details": {
          "loadTime": ${elapse}
        },
        "entityId": "${alias}"
      }`;

    const params: PutProjectEventsCommandInput = {
      events: [
        {
          data: JSON.parse(_data),
          timestamp: new Date(),
          type: "aws.evidently.custom",
        },
      ],
      project: project,
    };

    client.putProjectEvents(params, (err: { stack: any }) => {
      if (err) {
        console.error("Failed to put the event to CloudWatch", err, err.stack);
      }
    });
  }
}
