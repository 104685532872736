import { PropsWithChildren, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "app/store";
import { useGetSettingsQuery } from "features/settings";
import { loadVariations } from "./thunk";
import Loader from "components/loader/Loader";

export const EvidentlyWrapper: React.FC<PropsWithChildren> = ({ children }) => {
  const dispatch = useAppDispatch();
  const evidentlySettings = useGetSettingsQuery().data?.evidently;
  const { alias } = useAppSelector((state) => state.auth.user);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const asyncFunction = async () => {
      setIsLoading(true);
      await dispatch(loadVariations(evidentlySettings));
      setIsLoading(false);
    };

    if (alias && evidentlySettings) {
      asyncFunction();
    }
  }, [dispatch, evidentlySettings, alias]);

  if (isLoading) return <Loader loadingText="Loading..." />;
  return <>{children}</>;
};

export default EvidentlyWrapper;
