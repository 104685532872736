import { useSettings } from "../features/settings";

export enum Domain {
  BETA = "beta",
  GAMMA = "gamma",
  PROD = "prod",
}

export const useDomain = (): Domain => {
  return useSettings().settings.stage!.stageName;
};
